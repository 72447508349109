.profile {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.avatar {
    width: 100px;
    height: 100px;
}

.form {
    margin-top: 30px;
    max-width: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 14px;
}