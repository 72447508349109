.level {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #f0f0f0;
  color: #333;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.levelWithIcon {
  font-size: 26px;
}

.level.completed {
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.level:hover {
  background-color: #007aff;
  color: white;
}