.auth {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  &__subtitle {
    margin-top: 10px;
    font-size: 16px;
    font-weight: normal;
  }
}

.register-form {
  max-width: 400px;
  width: 100%;
  padding: 20px;

  &__title {
    text-align: center;
    font-size: 30px;
    margin-bottom: 20px;
  }

  &__inputs {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

@media (max-width: 375px) {
  .register-form {
    width: 90%;
    &__title {
      font-size: 24px;
    }
  }
}
