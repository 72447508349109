.modalBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-height: 100%;
  max-width: 700px;
  padding: 30px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 15px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(15px);
  border: 2px solid rgba(255, 255, 255, 0.2);
  overflow-y: auto;

  .closeButton {
    position: absolute;
    top: 15px;
    right: 15px;
    background-color: transparent;
    color: #555;
    font-size: 24px;
    transition: color 0.3s;

    &:hover {
      color: #ff4757;
    }
  }
}

.title {
  font-size: 28px;
  font-weight: bold;
  color: #1e90ff;
  margin-bottom: 10px;
  text-align: center;
}

.subtitle {
  font-size: 20px;
  font-weight: 500;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.editorContainer {
  width: 100%;
}

.navigationButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.arrowButton {
  font-size: 24px;
}

.disabled {
  opacity: 0.5;
}

@media (max-width: 430px) {
  .modalBox {
    padding: 5px;
    height: 95%;

    .closeButton {
      top: 0px;
      right: 4px;
    }
  }
  .title {
    font-size: 20px;
  }
  .subtitle {
    font-size: 15px;
  }
}
