.tabs {
  width: 100%;
}

.tabsTop {
  display: flex;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
}

.tabsContainer {
  display: flex;
  flex-wrap: nowrap;
  padding: 0;
  margin: 0;
  overflow-x: auto; /* Enable horizontal scrolling if needed */
}

.tabsList {
  display: flex;
}

.tabItem {
  display: inline-flex;
  align-items: center;
  padding: 4px 8px;
  margin: 0 4px;
  height: auto; /* Allow height to adjust to content */
  font-size: 14px;
  text-transform: none; /* Matches "sx" style */
}

.dragging {
  background-color: rgba(0, 0, 0, 0.1); /* Highlight dragging tab */
}

div > .tabMaterial {
  padding: 4px 8px;
  min-height: 32px;
  height: auto;
  font-size: 14px;
  text-transform: none;
  white-space: nowrap;
}

.addTabButton {
  height: 40px;
}

@media (max-width: 600px) {
  .tabItem {
    font-size: 12px; /* Adjust font size for smaller screens */
    padding: 2px 4px; /* Reduce padding for compact layout */
  }
}
