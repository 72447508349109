.characteristic {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    font-size: 20px;
    font-weight: 500;
    color: #333;
    margin-bottom: 10px;
    height: 28px;
}

.fields {
    display: flex;
}

.field {
    font-size: 20px;
    font-weight: 500;
    color: #333;
}

.dots {
    margin-top: 2px;
}